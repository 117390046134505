<template>
  <van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
    <div class="shareOverview">
      <div class="shareOverview-container">
        <div class="overview-header"></div>
        <div class="overview-wrap">
          <div class="title">今日调度概览</div>
          <div class="overview-center">
            <div class="center-data" @click="shareDeviceEvent">
              <p class="value">{{ totalCount.inPool || 0 }}</p>
              <p class="label">在池</p>
            </div>
            <div class="item-data-wrap">
              <div class="item-data" v-for="(item, index) in itemData" :key="index" @click="shareOrderEvent(item)">
                <p class="label">{{ item.label }}</p>
                <p class="value">{{ totalCount[item.key] || 0 }}</p>
              </div>
            </div>
          </div>
          <div class="overview-price">
            今日共享收益：
            <span>{{ totalPrice }}</span>
            元
          </div>
        </div>

        <div class="chart-container">
          <div class="title">科室共享调度概览</div>

          <div class="chart-wrap">
            <div class="chart-nav">
              <p class="nav-item">
                <span class="icon"></span>
                <span class="label">借出</span>
              </p>
              <p class="nav-item">
                <span class="icon"></span>
                <span class="label">借入</span>
              </p>
            </div>

            <div class="chart" ref="chart"></div>
          </div>
        </div>
      </div>
    </div>
  </van-pull-refresh>
</template>

<script>
import { getDate } from '@utils/utils.js'
import * as echarts from 'echarts'

export default {
  name: 'shareOverview',
  components: {},
  data() {
    return {
      isRefresh: false,

      totalCount: {},
      itemData: [
        {
          label: '预约',
          key: 'unexamined',
          path: 'shareOrder1'
        },
        {
          label: '待审批',
          key: 'unexamined',
          path: 'shareOrder2'
        },
        {
          label: '报障',
          key: 'unusable',
          path: 'shareOrder3'
        },
        {
          label: '已归还',
          key: 'reverted',
          path: 'shareOrder4'
        },
        {
          label: '借用中',
          key: 'occupied',
          path: 'shareOrder5'
        },
        {
          label: '锁定',
          key: 'locked',
          path: 'shareOrder6'
        }
      ],

      totalPrice: 0,

      myChart: null,
      chartConfig: {
        color: ['#266dff', '#ff8126'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          top: '5%',
          bottom: '2%',
          left: '2%',
          right: '2%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLine: {
            show: true
          }
        },
        yAxis: {
          type: 'value',
          nameGap: 20,
          splitNumber: 3,
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        series: []
      }
    }
  },
  created() {
    this.getData()
  },
  mounted() {
    this.myChart = echarts.init(this.$refs.chart)
    this.myChart.setOption(this.chartConfig)
  },
  methods: {
    onRefresh() {
      this.isRefresh = true
      this.getData(() => {
        setTimeout(() => {
          this.isRefresh = false
        }, 300)
      })
    },
    getData(callbak) {
      const data = {
        isTenantIdChild: 1,
        tenantId: this.$store.getters.userInfo.tenantId,
        timeType: 1,
        startTime: getDate() + ' 00:00:00',
        endTime: getDate() + ' 23:59:59'
      }
      Promise.all([this.countForTenantByStatus(data), this.countForTenantBorrow(data), this.sharePoolIncomeCount(data)])
          .then(res => {
            this.totalCount = res[0].data
            this.totalPrice = res[2].data.incomeCount || 0
            this.setChartOption(res[1].data)
            if (callbak) callbak()
          })
          .catch(() => {
            if (callbak) callbak()
          })
    },
    countForTenantByStatus(data) {
      // return this.$api.deviceOrg.countForTenantByStatus(data);
      return this.$api.deviceOrg
          .shareCount1({
            startTime: new Date(getDate() + ' 00:00:00').getTime(),
            endTime: new Date(getDate() + ' 23:59:59').getTime()
          })
    },
    countForTenantBorrow(data) {
      return this.$api.deviceOrg.countForTenantBorrow(data)
    },
    sharePoolIncomeCount(data) {
      return this.$api.deviceOrg.sharePoolIncomeCount(data)
    },

    setChartOption(list) {
      const xAxisData = []
      const data1 = []
      const data2 = []
      list.forEach(item => {
        xAxisData.push(item.tenantName)
        data1.push(item.loan)
        data2.push(item.borrow)
      })
      this.chartConfig.xAxis.data = xAxisData
      this.chartConfig.series = [
        {
          type: 'bar',
          barMaxWidth: 10,
          itemStyle: {
            normal: {
              barBorderRadius: [6, 6, 0, 0]
            }
          },
          data: data1
        },
        {
          type: 'bar',
          barMaxWidth: 10,
          itemStyle: {
            normal: {
              barBorderRadius: [6, 6, 0, 0]
            }
          },
          data: data2
        }
      ]

      this.myChart.setOption(this.chartConfig, true)
    },

    shareDeviceEvent() {
      this.$push('/deviceView1/share/shareDevice')
    },
    shareOrderEvent(item) {
      this.$push('deviceView1/share/shareOrder/' + item.path)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';

.container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.shareOverview {
  width: 100%;
  height: 100%;
  background: #fff;

  .shareOverview-container {
    min-height: 310px;
    background-image: url('~@assets/images/deviceView/share-overview-bg.png');
    background-size: 100% 310px;
    background-repeat: no-repeat;
    position: relative;

    .overview-header {
      width: 100%;
      height: 44px;
    }

    .overview-wrap {
      height: 266px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      > .title {
        font-size: 12px;
        color: #fff;
        position: absolute;
        left: 24px;
        top: 0;

        &::after {
          content: '';
          position: absolute;
          left: -10px;
          right: -10px;
          bottom: 0;
          height: 9px;
          background: linear-gradient(360deg, #05b1f2 0%, rgba(255, 255, 255, 0) 100%);
        }
      }

      .overview-center {
        width: 150px;
        height: 150px;
        background-image: url('~@assets/images/deviceView/share-overview-bg-img1.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .center-data {
          color: #fff;
          text-align: center;
          width: 90px;
          height: 90px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          .value {
            font-size: 36px;
            font-weight: 700;
          }

          .label {
            font-size: 14px;
            margin-top: 4px;
            line-height: 14px;
          }
        }

        .item-data-wrap {
          position: absolute;
          left: 50%;
          top: 50%;
          width: 1px;
          height: 1px;
        }

        .item-data {
          display: flex;
          flex-direction: column;
          align-items: center;
          $item1-h: 50px;
          $item1-v: 50px;
          $item2-h: 90px;
          $item2-v: -8px;
          $item3-h: 72px;
          $item3-v: 20px;

          &:nth-child(1) {
            left: $item1-h;
            bottom: $item1-v;
          }

          &:nth-child(2) {
            left: $item2-h;
            bottom: $item2-v;
          }

          &:nth-child(3) {
            left: $item3-h;
            top: $item3-v;
          }

          &:nth-child(4) {
            right: $item3-h;
            top: $item3-v;
          }

          &:nth-child(5) {
            right: $item2-h;
            bottom: $item2-v;
          }

          &:nth-child(6) {
            right: $item1-h;
            bottom: $item1-v;
          }

          position: absolute;

          .label {
            font-size: 12px;
            color: #fff;
            margin-bottom: 4px;
            text-align: center;
            width: 42px;
          }

          .value {
            width: 36px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            font-size: 14px;
            color: #0279e1;
            font-weight: 700;
            background: #fff;
            border-radius: 50%;
          }
        }
      }

      .overview-price {
        position: absolute;
        left: 12px;
        bottom: 0;
        font-size: 14px;
        color: #333;
        display: flex;
        align-items: center;

        span {
          font-size: 16px;
          font-weight: 700;
          color: #0279e1;
        }
      }
    }

    .chart-container {
      padding: 0 20px;
      padding-bottom: 20px;
      margin-top: 32px;

      > .title {
        display: inline-block;
        font-size: 12px;
        color: #333;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          left: -4px;
          right: -4px;
          bottom: 0;
          height: 9px;
          background: rgba(2, 121, 225, 0.2);
        }
      }

      .chart-wrap {
        height: 269px;
        width: 100%;
        padding: 24px;
        padding-top: 12px;
        margin-top: 10px;
        box-shadow: 0px 2px 12px 0px rgba(100, 101, 102, 0.12);

        .chart-nav {
          display: flex;

          .nav-item {
            display: flex;
            align-items: center;
            margin-right: 24px;

            &:first-child {
              .icon {
                background: #266dff;
              }
            }

            &:last-child {
              .icon {
                background: #ff8126;
              }
            }

            .icon {
              width: 8px;
              height: 8px;
              border-radius: 50%;
            }

            .label {
              font-size: 12px;
              color: #8c8c8c;
              margin-left: 8px;
            }
          }
        }

        .chart {
          margin-top: 12px;
          width: 100%;
          height: calc(100% - 28px);
        }
      }
    }
  }
}
</style>
